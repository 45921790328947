<template>
    <div>
    </div>
</template>


<template>
  <div>
   <!-- <button type="button" ref="caller" @click="open" style="border: 1px solid gray;">Open</button>
    <button type="button" ref="caller" @click="close" style="border: 1px solid gray;">close</button>  
    <v-dropdown ref="drop" @show-change="showChange" :manual="true">
      some contents you want
    </v-dropdown> -->
  </div>
</template>


<script>
//import Dropdown from "v-dropdown";
export default {
  components: {
   // "v-dropdown": Dropdown,
  },
  mounted(){
    console.log("mounted() starting.");
   /* var show;
    var that = this;
    function greet() {
      console.log('Hello world');
      show = !show;
      that.$refs.drop.visible(show)
    }

    setInterval(greet, 1000); */
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    showChange(val) {
        console.log("showChange() starting. val=", val);
      this.show = val;
    },
    open() {
        console.log("open() starting.");
      // this.$refs.drop.visible(true); //$emit("show", this.$refs.caller);
    },
    close() {
        console.log("close() starting.");
      // this.$refs.drop.visible(false); //$emit("show", this.$refs.caller);
    },
  },
};
</script>